<template>
  <div>
    <b-form @submit.prevent="saveData" ref="form" autocomplete="off">
      <b-row>
        <b-col lg="12" md="12" sm="12">
          <div class="label_input text-white">* Campos requeridos.</div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="names" class="label_input">Nombres*</label>
            <b-form-input
              id="names"
              size="sm"
              placeholder="Nombres"
              v-model="formData.names"
              :disabled="isDisable"
              autofocus
            />
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="last_names" class="label_input">Apellidos*</label>
            <b-form-input
              id="last_names"
              size="sm"
              placeholder="Apellidos"
              v-model="formData.last_names"
              :disabled="isDisable"
            />
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label class="label_input">Tipo de identificación*</label>
            <v-select
              :options="typeIndetificatios"
              label="value"
              class="style-chooser"
              placeholder="Seleccione"
              v-model="formData.type_identification"
              :disabled="isDisable"
            >
              <div slot="no-options">Sin opciones</div>
            </v-select>
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="identification" class="label_input"
              >Identificación*</label
            >
            <b-form-input
              id="identification"
              size="sm"
              placeholder="Identificación"
              v-model="$v.formData.identification.$model"
              :disabled="isDisable"
              @blur="$v.formData.identification.$touch()"
            />
            <span
              v-if="$v.formData.identification.$error"
              class="help-block text-danger text_error"
            >
              {{ messageValidation($v.formData.identification) }}
            </span>
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="email" class="label_input">Correo electrónico*</label>
            <b-form-input
              id="email"
              type="email"
              size="sm"
              placeholder="Correo eléctronico"
              v-model="$v.formData.email.$model"
              :disabled="isDisable"
              @blur="$v.formData.email.$touch()"
            />
            <span
              v-if="$v.formData.email.$error"
              class="help-block text-danger text_error"
            >
              {{ messageValidation($v.formData.email) }}
            </span>
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="cellphone" class="label_input">Celular*</label>
            <b-form-input
              id="cellphone"
              size="sm"
              placeholder="Celular"
              v-model="$v.formData.cellphone.$model"
              :disabled="isDisable"
              @blur="$v.formData.cellphone.$touch()"
            />
            <span
              v-if="$v.formData.cellphone.$error"
              class="help-block text-danger text_error"
            >
              {{ messageValidation($v.formData.cellphone) }}
            </span>
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label class="label_input">Género</label>
            <v-select
              :options="typeGenders"
              label="value"
              class="style-chooser"
              placeholder="Seleccione"
              v-model="formData.gender"
              :disabled="isDisable"
            >
              <div slot="no-options">Sin opciones</div>
            </v-select>
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="username" class="label_input">Usuario</label>
            <b-form-input
              id="username"
              size="sm"
              placeholder="Usuario"
              v-model="record.username"
              disabled
            />
          </div>
        </b-col>
      </b-row>
      <b-row v-if="!isDisable" class="footer-modal-customer">
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
            type="submit"
            size="lg"
            class="btn btn-success w-width"
            :disabled="isBusy || $v.$invalid"
          >
            <b-spinner class="mr-2" v-if="isBusy" small />
            Guardar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import ActionCRUD from "@/mixins/ActionCRUDsp";

export default {
  inject: ["ownerRepository"],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
    vSelect,
  },
  mixins: [ActionCRUD],
  props: {
    record: {
      type: Object,
      default: null,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isBusy: false,
      formData: {
        names: "",
        last_names: "",
        email: "",
        type_identification: null,
        identification: "",
        cellphone: "",
        gender: null,
      },
      typeIndetificatios: ["Cédula de ciudadanía", "Pasaporte"],
      typeGenders: ["Femenino", "Masculino", "Otro"],
      repository: "ownerRepository",
    };
  },
  mounted() {
    const me = this;
    if (me.record) { 
      me.formData = {
        uuid: me.record.uuid,
        names: me.record.names,
        last_names: me.record.last_names,
        email: me.record.email,
        type_identification: me.record.type_identification,
        identification: me.record.identification,
        cellphone: me.record.cellphone,
        gender: me.record.gender,
        userUuid: me.record.userUuid,
      }
    }
  },
  validations: {
    formData: {
      names: {
        required,
      },
      last_names: {
        required,
      },
      email: {
        required,
        email,
      },
      type_identification: {
        required,
      },
      identification: {
        required,
        numeric,
      },
      cellphone: {
        required,
        numeric,
        minLength: minLength(10),
				maxLength: maxLength(10),
      },
    },
  },
};
</script>

<style></style>
