<template>
  <b-form @submit.prevent="saveData" ref="form" autocomplete="off">
    <b-row class="mb-2">
      <b-col cols="12">
        <div class="title text-center text-white">Datos de la Empresa</div>
      </b-col>
      <b-col lg="12" md="12" sm="12">
        <div class="label_input text-white">* Campos requeridos.</div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="name" class="label_input">Nombre*</label>
          <b-form-input
            id="name"
            size="sm"
            placeholder="Nombre"
            v-model="formData.name"
            autofocus
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="nit" class="label_input">Nit*</label>
          <b-form-input
            id="nit"
            size="sm"
            placeholder="Nit"
            v-model="$v.formData.nit.$model"
            @blur="$v.formData.nit.$touch()"
          />
          <span
            v-if="$v.formData.nit.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.nit) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Plan*</label>
          <v-select
            :options="plans"
            label="name"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.planEnterprisePriceUuid"
            :reduce="(option) => option.uuid"
            @input="getPricePlan"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="discount" class="label_input">Descuento</label>
          <b-form-input
            id="discount"
            size="sm"
            type="number"
            placeholder="Precio"
            v-model="formData.discount"
            @keyup="generateDiscont"
            min="0"
            max="100"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="total" class="label_input">Precio Plan*</label>
          <b-form-input
            id="total"
            size="sm"
            placeholder="Precio"
            v-model="formData.total"
            disabled
          />
        </div>
      </b-col>
      <b-col
        lg="6"
        md="12"
        sm="12"
        class="mb-3"
        v-if="formData.planEnterprisePriceUuid"
      >
        <date-picker
          v-model="formData.initial_date"
          :attributes="attrs"
          :model-config="modelConfig"
          :available-dates="{ start: new Date(), end: null }"
          @input="dateFinish"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <b-row>
              <b-col lg="12" md="12" sm="12">
                <div role="group">
                  <label class="label_input">Fecha inicial*</label>
                  <b-form-input
                    size="sm"
                    :value="inputValue"
                    v-on="inputEvents"
                    readonly
                  />
                </div>
              </b-col>
            </b-row>
          </template>
        </date-picker>
      </b-col>
      <b-col
        lg="6"
        md="12"
        sm="12"
        class="mb-3"
        v-if="formData.planEnterprisePriceUuid"
      >
        <div role="group">
          <label class="label_input">Fecha final</label>
          <b-form-input size="sm" v-model="formData.final_date" disabled />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="cellphone_enterprise" class="label_input">Celular*</label>
          <b-form-input
            id="cellphone_enterprise"
            size="sm"
            placeholder="Celular"
            v-model="$v.formData.cellphone_enterprise.$model"
            @blur="$v.formData.cellphone_enterprise.$touch()"
          />
          <span
            v-if="$v.formData.cellphone_enterprise.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.cellphone_enterprise) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="address" class="label_input">Dirección</label>
          <b-form-input
            id="address"
            size="sm"
            placeholder="Dirección"
            v-model="formData.address"
          />
        </div>
      </b-col>
      <b-col cols="12">
        <div class="title text-center text-white mt-2">
          Datos del Administrador
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="names" class="label_input">Nombres*</label>
          <b-form-input
            id="names"
            size="sm"
            placeholder="Nombres"
            v-model="formData.names"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="last_names" class="label_input">Apellidos*</label>
          <b-form-input
            id="last_names"
            size="sm"
            placeholder="Apellidos"
            v-model="formData.last_names"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Tipo de identificación*</label>
          <v-select
            :options="typeIndetificatios"
            label="value"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.type_identification"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="identification" class="label_input"
            >Identificación*</label
          >
          <b-form-input
            id="identification"
            size="sm"
            placeholder="Identificación"
            v-model="$v.formData.identification.$model"
            @blur="$v.formData.identification.$touch()"
          />
          <span
            v-if="$v.formData.identification.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.identification) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="email" class="label_input">Correo electrónico*</label>
          <b-form-input
            id="email"
            type="email"
            size="sm"
            placeholder="Correo eléctronico"
            v-model="$v.formData.email.$model"
            @blur="$v.formData.email.$touch()"
          />
          <span
            v-if="$v.formData.email.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.email) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="cellphone" class="label_input">Celular*</label>
          <b-form-input
            id="cellphone"
            size="sm"
            placeholder="Celular"
            v-model="$v.formData.cellphone.$model"
            @blur="$v.formData.cellphone.$touch()"
          />
          <span
            v-if="$v.formData.cellphone.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.cellphone) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Género</label>
          <v-select
            :options="typeGenders"
            label="value"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.gender"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Tipo de perfil*</label>
          <v-select
            :options="profiles"
            label="name"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.profileUuid"
            :reduce="(option) => option.uuid"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
    </b-row>
    <b-row class="footer-modal-customer">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button
          type="submit"
          size="lg"
          class="btn btn-success w-width"
          :disabled="isBusy || $v.$invalid"
        >
          <b-spinner class="mr-2" v-if="isBusy" small />
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";
moment.locale("es");

import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import ActionCRUD from "@/mixins/ActionCRUDsp";
import { formatNumber } from "@/utils";

export default {
  inject: [
    "profileRepository",
    "planEnterprisePriceRepository",
    "ownerRepository",
  ],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
    vSelect,
    DatePicker,
  },
  mixins: [ActionCRUD],
  data() {
    return {
      isBusy: false,
      total: 0,
      formData: {
        names: "",
        last_names: "",
        email: "",
        profileUuid: null,
        type_identification: null,
        identification: "",
        cellphone: "",
        cellphone_enterprise: "",
        gender: null,
        name: "",
        nit: "",
        planEnterprisePriceUuid: null,
        initial_date: "",
        final_date: "",
        address: "",
        total: "",
        discount: 0,
      },
      typeIndetificatios: ["Cédula de ciudadanía", "Pasaporte"],
      typeGenders: ["Femenino", "Masculino", "Otro"],
      profiles: [],
      plans: [],
      repository: "ownerRepository",
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
      attrs: [
        {
          highlight: "orange",
          dates: new Date(),
        },
      ],
    };
  },
  async mounted() {
    const me = this;
    await me.loadProfiles();
    await me.loadPlans();
  },
  validations: {
    formData: {
      names: {
        required,
      },
      last_names: {
        required,
      },
      email: {
        required,
        email,
      },
      profileUuid: {
        required,
      },
      type_identification: {
        required,
      },
      identification: {
        required,
        numeric,
      },
      cellphone: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      cellphone_enterprise: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      name: {
        required,
      },
      nit: {
        required,
        numeric,
      },
      planEnterprisePriceUuid: {
        required,
      },
      initial_date: {
        required,
      },
      final_date: {
        required,
      },
      total: {
        required,
      },
    },
  },
  methods: {
    async loadProfiles() {
      const me = this;
      try {
        const { data } = await me.profileRepository.getAll();
        me.profiles = data;
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      }
    },
    async loadPlans() {
      const me = this;
      try {
        const { data } = await me.planEnterprisePriceRepository.getAll();

        data.sort((a, b) => {
          if (
            parseInt(a.planEnterprise.number_transportations) >
            parseInt(b.planEnterprise.number_transportations)
          ) {
            return 1;
          }
          if (
            parseInt(a.planEnterprise.number_transportations) <
            parseInt(b.planEnterprise.number_transportations)
          ) {
            return -1;
          }
          return 0;
        });

        me.plans = data.map((el) => ({
          name:
            el.planEnterprise.name +
            " " +
            el.type +
            " " +
            el.planEnterprise.number_users +
            " " +
            "U" +
            " " +
            el.planEnterprise.number_transportations +
            " " +
            "T",
          uuid: el.uuid,
          price: el.price,
          type: el.type,
        }));
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      }
    },
    dateFinish() {
      const me = this;
      let numberMonths = 1;
      const record = me.plans.find(
        (el) => el.uuid == me.formData.planEnterprisePriceUuid
      );

      if (record) {
        numberMonths =
          record.type == "Mensual"
            ? 1
            : record.type == "Trimestral"
            ? 3
            : record.type == "Semestral"
            ? 6
            : 12;
      }

      me.formData.final_date = moment(me.formData.initial_date)
        .add(numberMonths, "months")
        .format("YYYY-MM-DD");
    },
    generateDiscont() {
      const me = this;
      if (me.formData.discount > 0 && me.formData.discount <= 100) {
        const total = Number(me.total?.split(".").join(""));
        const discount = (Number(me.formData.discount) * total) / 100;
        me.formData.total = formatNumber(total - discount + "");
      } else {
        me.formData.total = me.total;
      }
    },
    getPricePlan() {
      const me = this;
      me.formData.initial_date = "";
      me.formData.final_date = "";
      const record = me.plans.find(
        (el) => el.uuid == me.formData.planEnterprisePriceUuid
      );
      me.total = record ? formatNumber(record?.price + "") : 0;
      me.formData.total = me.total;
    },
  },
};
</script>

<style lang="css" scoped>
.title {
  font-size: 20px;
  font-family: var(--fuente-primary);
  font-weight: bold;
  color: var(--black-color);
}
</style>
