<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" class="mt-4">
          <h3>Propietarios</h3>
        </b-col>
      </b-row>
      <b-card no-body class="card-global overflow-hidden">
        <base-action-table
          :records="records"
          :fields="fields"
          :busy="isBusy"
          :filtering="filtering"
          :module="module"
          @creating="handleCreating"
          @detail="handleDetail"
          @editing="handleEditing"
          @deleting="handleDeleting"
        />
      </b-card>
    </b-container>
    <base-modal :title="title" :id="id">
      <template v-slot:form>
        <div v-if="isAdmin">
          <form-owner @save="save" />
        </div>
        <div v-else>
          <form-edit-detail-owner
            :record="record"
            :isDisable="isDisable"
            @save="save"
          />
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script>
import { BContainer, BCol, BRow, BCard } from "bootstrap-vue";
import BaseActionTable from "@/components/base/BaseActionTable";
import BaseModal from "@/components/base/BaseModal";
import FormOwner from "./FormOwner";
import FormEditDetailOwner from "./FormEditDetailOwner";
import ActionCRUD from "@/mixins/ActionCRUDsp";

export default {
  inject: ["ownerRepository"],
  components: {
    BContainer,
    BCol,
    BRow,
    BCard,
    BaseActionTable,
    BaseModal,
    FormOwner,
    FormEditDetailOwner,
  },
  mixins: [ActionCRUD],
  data() {
    return {
      records: [],
      record: null,
      fields: [
        {
          key: "names",
          label: "Nombres",
          sortable: true,
        },
        {
          key: "last_names",
          label: "Apellidos",
          sortable: true,
        },
        {
          key: "identification",
          label: "Identificación",
        },
        {
          key: "cellphone",
          label: "Celular",
        },
        {
          key: "email",
          label: "Correo",
          sortable: true,
        },
        { key: "actions", label: "Accion" },
      ],
      isBusy: false,
      filtering: true,
      isDisable: false,
      isAdmin: true,
      title: "Crear propietario",
      id: "owner-modal",
      repository: "ownerRepository",
      module: "dd4d30f5-0911-4a52-a0ff-3f0c6ed410b6",
    };
  },
  async mounted() {
    const me = this
    await me.handleLoadData();
  },
  methods: {
    handleCreating() {
      const me = this
      me.title = "Crear propietario";
      me.isAdmin = true;
      me.$bvModal.show(me.id);
    },
    handleDetail(item) {
      const me = this
      me.title = "Detalle propietario";
      me.isAdmin = false;
      me.isDisable = true;

      me.record = { ...item, username: item.user.username };
      me.$bvModal.show(me.id);
    },
    handleEditing(item) {
      const me = this
      me.title = "Actualizar propietario";
      me.isAdmin = false;
      me.isDisable = false;

      me.record = { ...item, username: item.user.username };
      me.$bvModal.show(me.id);
    },
    async handleDeleting(uuid) {
      await this.del(uuid)
    },
  },
};
</script>

<style lang="css">
</style>
